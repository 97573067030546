.app,
body,
html,
#root {
    height: 100%;
    width: 100%;
    font-family: "Open Sans, Medium";
    overflow: auto;
}


#zmmtg-root {
    display: none;
}

input.MuiInputBase-input[type=text]:focus {
    outline: none !important;
    outline-offset: none !important;
}